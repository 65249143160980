
.thumbNailView img {
  width: 50px;
  height: 50px;
  object-fit: contain; }

.thumbNailMedium {
  width: 100px;
  height: 100px;
  object-fit: contain; }

.dropzone-empty * {
  pointer-events: none; }
.crop-container {
  width: fit-content;
  img {
    max-width: 250px;
    max-height: 250px;
    min-width: 100px;
    min-height: 100px;
    object-fit: contain; } }



.option-image {
  filter: invert(1);
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px; }

.ReactCrop, .transp-bg {
    background-color: white !important;
    background-image: linear-gradient(45deg, #DDD 25%, transparent 25%), linear-gradient(135deg, #DDD 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #DDD 75%), linear-gradient(135deg, transparent 75%, #DDD 75%);
    background-size: 25px 25px;
    background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px; }


.MuiExpansionPanelSummary-expandIcon {
  position: absolute !important;
  right: 100% !important; }

.ra-rich-text-input {
  max-width: 1040px; }


.________________ql-editor {
  padding: 20px !important;
  min-height: 200px;
  background: #F7F7F7;
  font-family: 'Open Sans' !important;
  font-size: 16px !important;
  line-height: 24px !important;
  a {
    color: #08c !important;
    text-decoration: none !important; }
  p {
  	margin: 0 !important; }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins' !important;
    font-weight: 500 !important; }
  h2 {
  	font-size: 31px !important;
  	line-height: 37px !important;
  	margin: 60px 0 12px !important; }
  h3 {
    margin: 60px 0 10px !important;
    font-size: 25px !important;
    line-height: 30px !important;
    text-align: center !important; } }

.ql-container {
      max-width: 700px; }

.inline-dropzone {
  > {
    label + div > span > div {
      width: 100%;
      height: 100%;
      box-sizing: border-box; }

    div {
      position: relative;
      padding: 0;
      width: 100%;
      height: 150px; } }

  .previews {
    display: none; } }


.grid-array > ul {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 250px); }

.grid-array .array-row {
    display: flex;
    flex-direction: column;
    border: none;
    background: #f8f8f8;
    padding: 10px;

    > p {
      display: none; } }

[class*="ra-input-slides"] {
   img {
    width: 100%; }
   > * {
    margin: 0 !important;
    width: 100%; } }

[class*="MuiSelect-select"] {
  min-width: 15em !important; }

.sort-ghost-cont {
   position: relative;
   .array-row {
      z-index: 10; } }

.disabled-cont {
  position: relative;
  text-decoration: line-through;

  label {
    text-decoration: line-through; }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute; } }

.long-text textarea {
  height: 100%; }

.MuiDialog-container .simple-form > * {
    padding: 0 0 20px !important; }




